import Catalogue from './Catalogue';
import LaButique from '../../SharedComponents/LaButique';
import Map from './Map';
import Welcome from './Welcome';

const HomePage = () => {
    return ( 
    <>
    <h1 className='underMaintenance'>Under Maintenance</h1>
        {/* <Welcome/>
        <Catalogue/>
        <LaButique displayCenterText={true}/>
        <Map/> */}
    </> );
}
 
export default HomePage;